<template>
  <div class="pagoda-add-layout-v1">
    <layout-header
      content-title="编辑营销建议"
      @backToList="handleBackToList"
    ></layout-header>
    <div class="pagoda-add-layout-v1__content">
      <div class="pagoda-add-layout-v1__title"></div>
      <div class="pagoda-add-layout-v1__main">
        <el-form
          class="el-form-reset-block"
          :rules="rules"
          :model="formData"
          ref="formRef"
          label-width="100px"
        >
          <el-row class="pagoda-form-grid">
            <el-col v-bind="$pagoda.form.grid">
              <el-form-item label="营销建议标题" prop="suggestions_title">
                <el-input
                  :maxlength="30"
                  v-model="formData.suggestions_title"
                  placeholder="请输入营销建议标题"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col v-bind="$pagoda.form.grid">
              <el-form-item label="企业标签" prop="tag_id">
                <el-cascader
                  placeholder="请选择企业标签"
                  v-model="formData.tag_id"
                  :options="companyTags"
                  @change="changeTag"
                  clearable
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col v-bind="$pagoda.form.grid">
              <el-form-item label="触发方式" prop="trigger">
                <el-select
                  v-model="formData.trigger"
                  placeholder="请选择触发方式"
                >
                  <el-option
                    v-for="item in typeList"
                    :key="item.item_key"
                    :label="item.item_value"
                    :value="item.item_key"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="pagoda-form-grid">
            <el-col :span="12">
              <el-form-item label="素材选择" prop="material_id">
                <member-transfer
                  v-model="formData.material_id"
                  title="素材标题"
                  type="素材"
                  ref="memberTransfer"
                  @clearValidate="clearValidateTips"
                  @selectedList="addSelectedList"
                ></member-transfer>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="pagoda-add-layout-v1__footer">
      <div class="pagoda-button-group">
        <el-button @click="handleCancel">取消</el-button>
        <el-button type="primary" v-debounce @click="handleSubmit">
          保存
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import customer from '@/services/api/customer'
import reach from '@/services/api/reach'
import memberTransfer from '@/components/member-transfer'
import { rules } from './rules'
export default {
  data() {
    return {
      formData: {
        suggestions_title: '',
        trigger: '',
        tag_id: '',
        material_id: ''
      },
      rules: {},
      tag_id: '',
      companyTags: [],
      typeList: [],
      type: 1 // type为1是添加素材/话术，2是编辑
    }
  },
  components: {
    memberTransfer
  },
  methods: {
    handleBackToList() {
      this.$router.push('/marketProposal')
    },
    // 获取企业标签
    getCompanyTag() {
      customer
        .getCompanyTag()
        .then((res) => {
          const data = res.data
          data.forEach((item) => {
            item.label = item.group_name
            item.value = item.group_name
            item.group_list.forEach((item) => {
              item.label = item.label_name
              item.value = item.id
            })
            item.children = item.group_list
            this.companyTags.push(item)
          })
        })
        .catch(() => {})
    },
    getTrigger() {
      reach
        .getTrigger()
        .then((res) => {
          this.typeList = res.data
        })
        .catch(() => {})
    },
    getProposalDetail(id) {
      reach
        .getProposalDetail(id)
        .then((res) => {
          this.formData.suggestions_title = res.data.suggestions_title
          this.formData.tag_id = res.data.tag_id
          this.formData.trigger = res.data.trigger_behavior
          this.formData.material_id = res.data.content_id
          this.$refs.memberTransfer.selectedList.push({
            title: res.data.title,
            id: res.data.content_id,
            isGetData: true
          })
        })
        .catch(() => {})
    },
    changeTag(arr) {
      this.formData.tag_id = arr[1]
    },
    editProposal() {
      const params = this.formData
      reach
        .editProposal(params)
        .then((res) => {
          this.$router.push('/marketProposal')
        })
        .catch(() => {})
    },
    addProposal() {
      delete this.formData.id
      const params = this.formData
      reach
        .addProposal(params)
        .then((res) => {
          this.$router.push('/marketProposal')
        })
        .catch(() => {})
    },
    clearValidateTips() {
      this.$refs.formRef.clearValidate('material_id')
    },
    handleSubmit() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          if (this.type === '1') {
            this.addProposal()
          } else {
            this.editProposal()
          }
        }
      })
    },
    // 搜索添加素材
    addSelectedList(selectedList = []) {
      console.log('selectedList--selectedList', selectedList)
      // this.formData.material_id = ''
      // this.formData.material_id = selectedList.join(',')
      this.$set(this.formData, 'material_id', selectedList.join(','))
    },
    handleCancel() {
      this.$router.go(-1)
    }
  },
  created() {
    this.queryObj = this.$route.query
    this.formData.id = this.queryObj.id
    this.type = this.queryObj.type
    this.getCompanyTag()
    this.getTrigger()
    if (this.type === '2') {
      this.getProposalDetail(this.formData.id)
    }
    this.rules = rules(this)
  }
}
</script>
<style lang="less">
.pagoda-add-layout-v1 {
  padding-left: 20px;
}
</style>
