export function rules(v) {
  return {
    suggestions_title: {
      required: true,
      message: '请输入营销建议标题',
      trigger: 'blur'
    },
    material_id: [
      {
        required: true,
        validator: (rule, value, callback) => {
          if (v.formData.material_id === '') {
            return callback(new Error('请选择素材'))
          }
          return callback()
        },
        trigger: 'change'
      }
    ],
    tag_id: [
      {
        required: true,
        message: '请选择企业标签',
        trigger: 'change'
      }
    ],
    trigger: {
      required: true,
      message: '请选择触发方式',
      trigger: 'change'
    }
  }
}
